/**
 * ready
 * A jQuery-less `$(document).ready()`.
 * @param {Function} next A function containing code to run once website has successfully loaded.
 */
 var ready = function (next) {
  if (document.readyState !== "loading") {
    // Document is already ready, call the callback directly.
    next();
  } else if (document.addEventListener) {
    // All modern browsers to register DOMContentLoaded.
    document.addEventListener("DOMContentLoaded", next);
  } else {
    // Otherwise cater for old IE browsers.
    document.attachEvent("onreadystatechange", function() {
      if (document.readyState === "complete") {
        next();
      }
    });
  }
}

// Vanilla JS `document.ready(function(){});
ready(function() {

/**
 * determinePersonalisedPlate
 * Receives values submitted and returns three plates partly determined by the values.
 * @param {Object} values An object containing the name and value of the inputs submitted.
 */
var determinePersonalisedPlate = function (values) {
  // [Entire Array  [Question  [Values To Answers  [Categories To Include  ]]]]
  var valueToCategory = [
    [
      // Question
      [0, ["cars", "cars"]], // 0 value with high-weighting
      [1, ["cars", "movies"]], // 1 value
      [2, ["movies", "movies"]] // 2 value with high-weighting
    ],
    [
      [0, ["sporty", "sporty"]],
      [1, [
          "sporty",    // category of plates to include
          "characters" // category of plates to include
          ]
      ],
      [2, ["characters", "characters"]]
    ],
    [
      [0, ["world", "world"]],
      [1, ["world", "perth"]],
      [2, ["perth", "perth"]]
    ],
    [
      [0, ["celebrity", "celebrity"]],
      [1, ["celebrity", "movies", "characters"]],
      [2, ["movies", "characters", "movies", "characters"]]
    ]
  ];

  const plates = [
    { category: "cars",       plate: "BUSHW4CKR"  },
    { category: "cars",       plate: "C0MM0D0R3"  },
    { category: "cars",       plate: "CX9 2019"   },
    { category: "cars",       plate: "D3LOR3AN"   },
    { category: "cars",       plate: "L3XUS IS"   },
    { category: "cars",       plate: "N1SS4N"     },
    { category: "cars",       plate: "OPENROAD"   },
    { category: "cars",       plate: "SUBIEBRO"   },
    { category: "cars",       plate: "SUBIEGAL"   },
    { category: "cars",       plate: "XPLORE"     },
    { category: "celebrity",  plate: "3MIN3M"     },
    { category: "celebrity",  plate: "BEYONCE89"  },
    { category: "celebrity",  plate: "DRAK3"      },
    { category: "celebrity",  plate: "GR4ND3"     },
    { category: "celebrity",  plate: "K8YP3RRY"   },
    { category: "celebrity",  plate: "KH4L1D"     },
    { category: "celebrity",  plate: "M3SS1"      },
    { category: "celebrity",  plate: "MCHAMM3R"   },
    { category: "celebrity",  plate: "ST4NL33"    },
    { category: "celebrity",  plate: "THEROCK"    },
    { category: "celebrity",  plate: "TMHOLLAND"  },
    { category: "characters", plate: "FRODDO"     },
    { category: "characters", plate: "MICKEYMSE"  },
    { category: "characters", plate: "MINIMSE"    },
    { category: "characters", plate: "SPID3RMAN"  },
    { category: "feminine",   plate: "BEACHLVR"   },
    { category: "feminine",   plate: "FLWRPWR"    },
    { category: "feminine",   plate: "GRLPWR"     },
    { category: "feminine",   plate: "GYMCHIC"    },
    { category: "feminine",   plate: "LULUL3M0N"  },
    { category: "feminine",   plate: "MISSYOGA"   },
    { category: "feminine",   plate: "OPRAH58"    },
    { category: "feminine",   plate: "P4RTYGRL"   },
    { category: "feminine",   plate: "SK8RGRL"    },
    { category: "feminine",   plate: "SOFRESH"    },
    { category: "feminine",   plate: "SPICEGRL"   },
    { category: "feminine",   plate: "STARBCKS"   },
    { category: "feminine",   plate: "SUMMERLUV"  },
    { category: "masculine",  plate: "GR8TM8T"    },
    { category: "masculine",  plate: "GYMBRO"     },
    { category: "masculine",  plate: "GYMJUNKI3"  },
    { category: "masculine",  plate: "LUV2GYM"    },
    { category: "masculine",  plate: "M4CH0M4N"   },
    { category: "masculine",  plate: "MRMUSCLES"  },
    { category: "masculine",  plate: "PARTYDUDE"  },
    { category: "masculine",  plate: "SK8RB0Y"    },
    { category: "masculine",  plate: "SURFRDUDE"  },
    { category: "movies",     plate: "AV3NGERS"   },
    { category: "movies",     plate: "LOTR"       },
    { category: "movies",     plate: "UNCLEBUK"   },
    { category: "perth-wa",   plate: "CITYLYF"    },
    { category: "perth-wa",   plate: "CMONTGAL"   },
    { category: "perth-wa",   plate: "CMONTGUY"   },
    { category: "perth-wa",   plate: "COTTLYF"    },
    { category: "perth-wa",   plate: "CTYBEACH"   },
    { category: "perth-wa",   plate: "FREOLYF"    },
    { category: "perth-wa",   plate: "PERTH4LYF"  },
    { category: "perth-wa",   plate: "PERTHCHIC"  },
    { category: "perth-wa",   plate: "PERTHDUDE"  },
    { category: "perth-wa",   plate: "PERTHLUVR"  },
    { category: "perth-wa",   plate: "RCKNGHM"    },
    { category: "perth-wa",   plate: "ROTTOLUVR"  },
    { category: "perth-wa",   plate: "SCARBRO"    },
    { category: "perth-wa",   plate: "SUNSETCST"  },
    { category: "sporty",     plate: "BBALLYF"    },
    { category: "sporty",     plate: "COLLINGWD"  },
    { category: "sporty",     plate: "CR7FAN"     },
    { category: "sporty",     plate: "CRICKETLVR" },
    { category: "sporty",     plate: "D0CK3RLVR"  },
    { category: "sporty",     plate: "NTB4LL"     },
    { category: "sporty",     plate: "PERTHH3AT"  },
    { category: "sporty",     plate: "RUGBYLVR"   },
    { category: "sporty",     plate: "SOCCERLVR"  },
    { category: "sporty",     plate: "W4LL4B13S"  },
    { category: "world",      plate: "BALIBLISS"  },
    { category: "world",      plate: "BALIGIRL"   },
    { category: "world",      plate: "BRTSHBABE"  },
    { category: "world",      plate: "CALIGIRL"   },
    { category: "world",      plate: "CALIGUY"    },
    { category: "world",      plate: "DSTIN8ION"  },
    { category: "world",      plate: "GLOBALEXP"  },
    { category: "world",      plate: "GLOBETRTTR" },
    { category: "world",      plate: "GR82BUK"    },
    { category: "world",      plate: "GR82BUSA"   },
    { category: "world",      plate: "LUV2TRVL"   },
    { category: "world",      plate: "MURICA"     },
    { category: "world",      plate: "THAILND"    },
    { category: "world",      plate: "WRLDTRVL"   }
  ];

  var required = [];

  Object.entries(values).forEach((element, index) => {
    // Push into required..
    // the current question's (index)..
    // set of values (element)..
    // then get the actual value response (1).
    required.push(valueToCategory[index][element[1]][1]);
  });

  var finalPlates = [];

  required.forEach(arrayOfSets => {
    arrayOfSets.forEach(set => {
      plates.forEach(plate => {
        if (plate.category == set) {
          finalPlates.push(plate.plate);
        }
      });
    });
  });

  plates.forEach(element => {
    if (element.category === "masculine" || element.category === "feminine") {
      finalPlates.push(element.plate);
    }
  });

  var a = Math.floor(Math.random() * finalPlates.length);
  var b = Math.floor(Math.random() * finalPlates.length);
  var c = Math.floor(Math.random() * finalPlates.length);

  while (a === b || a === c || b === c) {
    console.log("Found a duplicate, replacing:");
    console.log(a, b, c);
    a = Math.floor(Math.random() * finalPlates.length);
    b = Math.floor(Math.random() * finalPlates.length);
    c = Math.floor(Math.random() * finalPlates.length);
  }

  var randomlyChosenPlates = [finalPlates[a], finalPlates[b], finalPlates[c]];

  return randomlyChosenPlates;
}

  // Form values store.
  const formValues = {
    "personalisation--question-1": 1,
    "personalisation--question-2": 1,
    "personalisation--question-3": 1,
    "personalisation--question-4": 1
  };

  // const html2canvas = require('html2canvas');

  var platesToDisplay = [];

  // [SECTION] Personalise Form
  var sectionPersonaliseForm = [
    ...document.getElementsByClassName("section--personal-form")
  ][0];

  // [SECTION] Old School Way
  var sectionOldSchoolWay = [
    ...document.getElementsByClassName("section--old-school-way")
  ][0];

  // [SECTION] Results
  var sectionResults = [
    ...document.getElementsByClassName("section__results")
  ][0];

  sectionResults.style.display = "none";

  // Form Inputs within the section.
  var personaliseFormInput = [
    ...document.getElementsByClassName("personalisation-form-input")
  ];

  // Submit button within the form.
  var personaliseSubmitButton = [
    ...document.getElementsByClassName("personalisation__submit")
  ][0];

  // Submit button within the form.
  var editResponsesButton = [
    ...document.getElementsByClassName("results__function-buttons--edit")
  ][0];

  // For each input,
  // attach an event listener to watch for changes,
  // then update the form values store.
  personaliseFormInput.forEach(element => {
    element.addEventListener("change", function() {
      console.debug(document.getElementById("personalisation--question-1"));
      formValues[this.name] = parseInt(this.value);
    });
  });

  function addCharacterInTime(current, char) {
    return current + char;
  }

  personaliseSubmitButton.addEventListener("click", function(e) {
    e.preventDefault();
    this.disabled = true;
    this.value = "PREDICTING ";
    var ellipses = 0;

    var ellipsesAdd = setInterval(() => {
      ellipses++;
      if (ellipses == 4) {
        clearInterval(ellipsesAdd);
        transitionToResults();
      } else {
        this.value = addCharacterInTime(this.value, ".");
      }
    }, 500);
    platesToDisplay = determinePersonalisedPlate(formValues);
    resultsDescription();
  });

  editResponsesButton.addEventListener("click", function(e){
    e.preventDefault();
    transitionFromResults();
  })

  // Jump to Content event listener to slide to content.
  document
    .getElementsByClassName("button__jump-to-content")[0]
    .addEventListener("click", e => {
      scrollTo(document.getElementsByClassName("section--personal-form")[0]);
    });

  function transitionToResults() {
    document.getElementsByClassName("section__site-overlay")[0].style.height =
      document.getElementsByTagName("body")[0].offsetHeight + "px";

      var siteUrl = SITE_BASE_URL + "/index.php?t=";
      var appId = "641457283352427";

      setTimeout(() => {
        personaliseSubmitButton.value = "PREDICT MY PURCHASE";
        sectionPersonaliseForm.style.display = "none";
        sectionOldSchoolWay.style.display = "none";
        sectionResults.style.display = "flex";
        personaliseSubmitButton.disabled = false;

      document.getElementById("results__result-plate--1").innerText =
        platesToDisplay[0];
      document.getElementById("results__result-plate-share--1").href="https://www.facebook.com/dialog/share?app_id=" + appId + "&display=popup&href=" + siteUrl + encodeURIComponent(platesToDisplay[0]);   
      document.getElementById("results__result-plate--2").innerText =
        platesToDisplay[1];
        document.getElementById("results__result-plate-share--2").href="https://www.facebook.com/dialog/share?app_id=" + appId + "&display=popup&href=" + siteUrl + encodeURIComponent(platesToDisplay[1]);
      document.getElementById("results__result-plate--3").innerText =
        platesToDisplay[2];
        document.getElementById("results__result-plate-share--3").href="https://www.facebook.com/dialog/share?app_id=" + appId + "&display=popup&href=" + siteUrl + encodeURIComponent(platesToDisplay[2]);

      document.getElementsByClassName(
        "site-header__logo--plate-predictor"
      )[0].style.display = "none";
      document.getElementsByClassName(
        "site-header__sub-heading"
      )[0].style.display = "none";
      document.getElementsByClassName("site-header__copy")[0].style.display =
        "none";
      document.getElementsByClassName(
        "site-header__results-logo"
      )[0].style.display = "block";
      document.getElementsByClassName(
        "button__jump-to-content"
      )[0].style.display = "none";
      document.getElementsByClassName("section__site-overlay")[0].style.height =
        "0vh";
    }, 1000);
  }

  function transitionFromResults() {
    document.getElementsByClassName("section__site-overlay")[0].style.height =
      document.getElementsByTagName("body")[0].offsetHeight + "px";

    setTimeout(() => {
      sectionPersonaliseForm.style.display = "flex";
      sectionOldSchoolWay.style.display = "flex";
      sectionResults.style.display = "none";

      document.getElementsByClassName(
        "site-header__logo--plate-predictor"
      )[0].style.display = "block";
      document.getElementsByClassName(
        "site-header__sub-heading"
      )[0].style.display = "block";
      document.getElementsByClassName("site-header__copy")[0].style.display =
        "block";
      document.getElementsByClassName(
        "site-header__results-logo"
      )[0].style.display = "none";
      document.getElementsByClassName(
        "button__jump-to-content"
      )[0].style.display = "block";

      document.getElementsByClassName("section__site-overlay")[0].style.height =
        "0vh";
    }, 1000);
  }

  /**
   * scrollTo
   * Scrolls the window to the top of the supplied element.
   * @param {HTMLCollection} element A DOM element.
   */
  function scrollTo(element) {
    window.scroll({
      behavior: "smooth",
      left: 0,
      top: element.offsetTop
    });
  }

  function resultsDescription() {
    document.getElementsByClassName('results__description')[0].innerHTML = "";
    var defaultText = "You are a fun-loving extrovert who keeps spinning those wheels. You might live with chaos all around you, but that all changes as soon as you get on the karaoke mic. Here are our suggestions.";
    var q1 = "";
    var q2 = "";
    var q3 = "";
    var q4 = "";
    if(document.getElementById('personalisation--question-1').value == 0) {
      q1 = "Your car is an extension of your personality – a statement. ";
      defaultText = "";
    }
    if(document.getElementById('personalisation--question-1').value == 1) {
      q1 = "You like your car and you care for it, but it’s not like you’ve given it a name or kiss it goodnight! ";
      defaultText = "";
    }
    if(document.getElementById('personalisation--question-1').value == 2) {
      q1 = "You’re a practical sort, you don’t need to shout for recognition. ";
      defaultText = "";
    }
    if(document.getElementById('personalisation--question-2').value == 0) {
      q2 = "At the same time, you love the excitement friendly competition brings. ";
      defaultText = "";
    }
    if(document.getElementById('personalisation--question-2').value == 1) {
      q2 = "Having said that, you think your car is a bit like you: a jack of all trades but a master of none – and that’s okay! ";
      defaultText = "";
    }
    if(document.getElementById('personalisation--question-2').value == 2) {
      q2 = "You’re also a deep thinker, you like to consider your options before making a decision. ";
      defaultText = "";
    }
    if(document.getElementById('personalisation--question-3').value == 0) {
      q3 = "Ultimately, you’re all about the journey – not the destination. ";
      defaultText = "";
    }
    if(document.getElementById('personalisation--question-3').value == 1) {
      q3 = "And like your car, you love a good road trip… but you weren’t made for long distances! ";
      defaultText = "";
    }
    if(document.getElementById('personalisation--question-3').value == 2) {
      q3 = "They say “home is where the heart (and garage) is”, and you totally agree. ";
      defaultText = "";
    }
    if(document.getElementById('personalisation--question-4').value == 0) {
      q4 = "But whether in the car, shower or karaoke booth, you’ll be the one belting out the anthems. ";
      defaultText = "";
    }
    if(document.getElementById('personalisation--question-4').value == 1) {
      q4 = "As someone who’s known to sit on the fence, you can never decide on a movie to watch or song to listen to… or custom plate! ";
      defaultText = "";
    }
    if(document.getElementById('personalisation--question-4').value == 2) {
      q4 = "But at the end of the day, you just want to put it in ‘park’ and curl up with a good movie. ";
      defaultText = "";
    }
    var result = defaultText.concat(q1, q2, q3, q4);
    document.getElementsByClassName('results__description')[0].innerHTML = result;
  }

});